import * as React from 'react';

import { graphql } from 'gatsby';

import CampaignDetailsModule from '../../modules/campaignDetails';
import { Typography } from '@mui/material';

const CampaignDetailsPage = (props: any) => {
  return (
    <CampaignDetailsModule campaignKey="soldiers-2023" showDonationDescription={false}>
      <Typography paragraph={true}>Dear visitor,</Typography>

      <Typography paragraph={true}>
        Unfortunately, the war in Ukraine is still ongoing. We at BI have decided to take an active position and support our colleagues, relatives, friends, and all Ukrainians whose lives and destinies are still impacted by this war as much as we can.
      </Typography>

      <Typography paragraph={true}>
        Our friends who are based in Kyiv contacted us recently to support them in collecting scarce goods which will be then delivered directly to the soldiers defending the freedom of Ukraine and Europe on a battlefield in Eastern Ukraine (Kharkiv & Donetsk area).
      </Typography>

      <Typography paragraph={true}>
        Our friends who are based in Kyiv contacted us recently to support them in collecting scarce goods which will be then delivered
        directly to the civilians on-site in Eastern Ukraine (Kharkiv & Donetsk area).
      </Typography>

      <Typography paragraph={true}>
        You can either bring any listed goods below by yourself or can donate any amount of money for the right cause, so we purchase them
        and pass it for further delivery.
      </Typography>

      <Typography paragraph={true}>
        The purchased goods and supplies will be delivered afterwards by our persons of trust directly to the place where they are the most
        needed now. As always, we will provide a final (photo-) report about the collected donations and delivery status.
      </Typography>

      <Typography paragraph={true}>
        For any questions, please contact Sergej Andrushchenko (BI X) via MS Teams or email at{' '}
        <a href="mailto:sergej@ingelheim.mobi">sergej@ingelheim.mobi</a>.
      </Typography>

      <Typography paragraph={true}>
        Most urgently needed goods now are:
        <ul>
          <li>Tablets</li>
          <li>Powerbanks</li>
          <li>Medical tourniquets</li>
          <li>Thermal underwear</li>
          <li>Sleeping bags</li>
          <li>Tactical glasses</li>
          <li>Coffee, tea, chocolate, energy bars, canned food</li>
        </ul>
      </Typography>

      <Typography paragraph={true}>
        Bank account for donations:<br/>
        Sergej Andrushchenko<br/>
        DE46 1203 0000 1009 1838 13<br/>
        Purpose: “Help for soldiers in Ukraine”
      </Typography>

      <Typography paragraph={true}>
        We stay strong together.
        Thanks a lot in advance for your active position and support!
      </Typography>

    </CampaignDetailsModule>
  );
};

export default CampaignDetailsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
